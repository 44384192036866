import { inject } from "vue";
import { GraphqlAPI } from "../core/graphql-api";
import {
  ANNO_TYPES,
  BROADCAST_MESSAGE,
  ERROR_TOAST,
} from "../core/constants.js";
const graphqlServer = import.meta.env.VITE_GRAPHQL_SERVER_V1;
import { getters, mutations } from "../store/index.js";
import ProjectService from "./projectService.js";
import tinycolor from "tinycolor2";
import * as A from "@automerge/automerge/next";

const stampQueryNames = {
  InkDocumentStampInput: "inkDocumentStamp",
  CircleDocumentStampInput: "circleDocumentStamp",
  SquareDocumentStampInput: "squareDocumentStamp",
  ArrowDocumentStampInput: "arrowDocumentStamp",
  TextBoxDocumentStampInput: "textBoxDocumentStamp",
  ImageDocumentStampInput: "imageDocumentStamp",
};
const stampAnnotationName = {
  InkAnnotation: "InkDocumentStamp",
  CircleAnnotation: "CircleDocumentStamp",
  SquareAnnotation: "SquareDocumentStamp",
  ArrowAnnotation: "ArrowDocumentStamp",
  TextBoxAnnotation: "TextBoxDocumentStamp",
  ImageAnnotation: "ImageDocumentStamp",
};

export default class AnnotationService {
  fetch = null;
  constructor() {
    this.accessToken = localStorage.getItem("accessToken");
    this.graphqlAPIService = new GraphqlAPI(graphqlServer, this.accessToken);
    this.getToolPreset = this.getToolPreset.bind(this);
    this.projectService = new ProjectService();
    this.fetch = inject("$fetch");
  }

  async getToolPreset(filters = {}) {
    const {} = filters;
    const presets = await this.graphqlAPIService.getToolPresets();
    const tempPreset = {
      activePresets: [
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "UNDERLINE" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "MARKER" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "INK" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "CROSSLINE" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "SQUARE" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "CIRCLE" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "ARROW" },
        { addedAt: "2024-01-13T16:37:44.539Z", index: 0, kind: "TEXT_BOX" },
        { addedAt: "2024-01-14T05:59:19.842Z", index: 0, kind: "PIXEL_ERASER" },
      ],
      inkPresets: [
        {
          index: 0,
          addedAt: "2023-11-14T11:24:50.678Z",
          color: "#EF4444FF",
          lineWidth: 2,
        },
        {
          index: 1,
          addedAt: "2023-11-14T11:24:50.678Z",
          color: "#0EA5E9FF",
          lineWidth: 6,
        },
        {
          index: 2,
          addedAt: "2023-11-14T11:24:50.678Z",
          color: "#000000FF",
          lineWidth: 10,
        },
      ],
      crosslinePresets: [
        { index: 0, addedAt: "2024-01-13T16:37:44.539Z", color: "#000000FF" },
      ],
      markerPresets: [
        { index: 0, addedAt: "2023-11-03T19:11:48.488Z", color: "#FFED69FF" },
        { index: 1, addedAt: "2023-11-03T19:11:48.488Z", color: "#D3E660FF" },
        { index: 2, addedAt: "2023-11-03T19:11:48.488Z", color: "#22D3EEFF" },
      ],
      underlinePresets: [
        { index: 0, addedAt: "2023-11-03T19:11:46.874Z", color: "#EF4444FF" },
      ],
      arrowPresets: [
        {
          index: 0,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#EF4444FF",
          lineWidth: 2,
        },
        {
          index: 1,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#0EA5E9FF",
          lineWidth: 6,
        },
        {
          index: 2,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#000000FF",
          lineWidth: 10,
        },
      ],
      circlePresets: [
        {
          index: 0,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#EF4444FF",
          lineWidth: 2,
        },
        {
          index: 1,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#0EA5E9FF",
          lineWidth: 6,
        },
        {
          index: 2,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#000000FF",
          lineWidth: 10,
        },
      ],
      squarePresets: [
        {
          index: 0,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#EF4444FF",
          lineWidth: 2,
        },
        {
          index: 1,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#0EA5E9FF",
          lineWidth: 6,
        },
        {
          index: 2,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#000000FF",
          lineWidth: 10,
        },
      ],
      textBoxPresets: [
        {
          index: 0,
          addedAt: "2024-01-13T16:37:44.539Z",
          color: "#000000FF",
          fontFamily: "SANS_SERIF",
          fontSize: 14,
          fontWeight: "W400",
          textAlignment: "START",
        },
      ],
      eraserPresets: [
        { index: 0, addedAt: "2024-01-14T05:59:19.842Z", lineWidth: 9 },
      ],
    };
    localStorage.setItem("tool-presets", JSON.stringify(tempPreset));
    return presets;
  }
  async getDocumentData({ projectId }) {
    const userName = localStorage.getItem("name");
    const userEmail = localStorage.getItem("email");
    const {
      ownerProfile: owner = {},
      sessionUserRole: userRole,
      permissions,
    } = await this.projectService.getProject({ projectId });
    return { owner, userRole, userName, userEmail, permissions };
  }

  async getAnnotations(payload, handle) {
    const { projectId, documentId } = payload;
    const d = await handle.doc();
    const docValue = handle.docSync();
    const annotations = docValue.annotations;
    console.log({ rawAMObj: annotations });

    // const annotations_reordered = JSON.parse(
    //   JSON.stringify([...new Map(Object.entries(annotations)).values()])
    // );
    const annotations_reordered = [
      ...new Map(Object.entries(annotations)).values(),
    ];
    for (const [idx, annotation] of Object.entries(annotations_reordered)) {
      let convertedAnnotationObject = annotation;
      let imageId = null;
      if (!annotation.__typename) {
        // this is new annotation structured object
        convertedAnnotationObject =
          this.convertNewAnnotationObjectToOldVersion(annotation);
          imageId = convertedAnnotationObject?.blobId;
      }
      const { id, __typename } = convertedAnnotationObject;
      if (__typename === ANNO_TYPES.IMAGE) {
        // if (imageId === null) {
        //   imageId = annotation.content;
        // }
        try {
          console.log({imageId});
          //force fully ignore if the blogid is not available at this moment;
          if(imageId !== undefined && imageId !== null){
            let blobContent = getters.getAnnotationBlob(imageId);
            if (blobContent) {
              annotation.content = blobContent;            
            } else {
                let annotationBlob =
                  await this.graphqlAPIService.downloadAnnotationBlob(imageId);
                annotation.content =
                  annotationBlob?.downloadAnnotationBlob?.content;
            }         
          
          }
    
        } catch (error) {
          if (this.fetch)
            this.fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
              type: ERROR_TOAST,
              title: "Error while syncing your annotation.",
              message: "Image annotation sync failed.",
            });
        }
      }
    }
    return annotations_reordered.map((obj) => {
      return {
        ...obj,
        ...(obj.__typename
          ? {}
          : this.convertNewAnnotationObjectToOldVersion(obj)),
      };
    });
  }
  async createAnnotation(projectId, documentId, objectData, handle) {
    const docValue = handle.docSync();
    if (!objectData["deletedAt"]) {
      objectData["deletedAt"] = "";
    }

    const {
      __typename,
      id,
      page,
      createdAt,
      addedAt,
      deletedAt,
      color,
      lineWidth,
      paths,
      kind,
      rects,
      text,
      rect,
      sourcePoint,
      targetPoint,
      fontFamily,
      fontSize,
      fontWeight,
      textAlignment,
      anchorPoint,
      filename,
      content,
      commentId,
      newbie = true,
    } = objectData;
    
    if (__typename === ANNO_TYPES.IMAGE) {
      let imageId = documentId + "_" + objectData.id;
      mutations.addAnnotationBlob(imageId, objectData.content);
      if (newbie) {
        await this.graphqlAPIService.uploadAnnotationBlob(
          imageId,
          documentId,
          filename??imageId,
          objectData.content
        );
      }
      // objectData.content = imageId;
      objectData.blobId = imageId;
    }
    const newAnnotationObject =
      this.convertOldAnnotationObjectToNewVersion(objectData);      
    handle.change((d) => {
      if((kind === 'Reply'|| kind?.val === 'Reply')&&objectData?.status === 'EDITED'){
        d.annotations[newAnnotationObject.id].text = newAnnotationObject?.text;
      }else if((kind === 'Reply'|| kind?.val === 'Reply')&& objectData?.deletedAt){
        d.annotations[newAnnotationObject.id].deleted = Date.now();
      }else if(newAnnotationObject?.type?.val === 'textbox' && (!text || text.trim().length === 0) ){
        // if the text is empty we remove it from automerge object.
        delete d.annotations[newAnnotationObject.id]
      }
      else{
        d.annotations[newAnnotationObject.id] = newAnnotationObject;
      }

    });
    return true;
  }

  async getStamps({ projectId }) {
    const { fetchSavedAnnotations: stamps = [] } =
      (await this.graphqlAPIService.getStamps()) ?? {};
    const finalStampArray = stamps.map((s) => {
      const { annotation } = s;
      try {
        const parsedAnnotation = JSON.parse(annotation);
        const { __typename } = parsedAnnotation;
        parsedAnnotation["__typename"] = stampAnnotationName[__typename];
        parsedAnnotation["pageSize"] = [595.35, 841.995];
        return { ...s, annotation: parsedAnnotation };
      } catch (error) {
        return {};
      }
    });
    return finalStampArray;
  }

  async getStampPreview(payload) {
    const newAnnoModel = this.convertOldAnnotationObjectToNewVersion(payload);
    newAnnoModel.content = null;
    console.log({beforeStampPreviewCall:newAnnoModel});
    const { generateStampPreview } =
      await this.graphqlAPIService.getStampPreview({
        annotation: JSON.stringify(newAnnoModel),
      });
    return { preview: generateStampPreview };
  }
  async createStamp(payload) {
    const {
      __typename,
      paths,
      color,
      lineWidth,
      rect,
      sourcePoint,
      targetPoint,
      fontFamily,
      fontSize,
      fontWeight,
      textAlignment,
      text,
      filename,
      content,
      name,
      pageSize,
    } = payload;
    const newAnnoModel = this.convertOldAnnotationObjectToNewVersion(payload)
    newAnnoModel.content = null;    
    const queryPayload = { annotation: JSON.stringify({ ...newAnnoModel }), name };
    const p = await this.graphqlAPIService.createStamp(queryPayload);
    return true;
  }

  async renameStamp(payload) {
    await this.graphqlAPIService.renameStamp(payload);
    return true;
  }

  async deleteStamp(stampId) {
    return await this.graphqlAPIService.deleteStamp(stampId);
  }

  invertObject(obj) {
    const invertedObj = {};
    for (const [key, value] of Object.entries(obj)) {
      invertedObj[value] = key;
    }
    return invertedObj;
  }
  //Only verified with SQUARE annotation. dont try others...
  convertOldAnnotationObjectToNewVersion(objectData) {
    const {
      __typename,
      id,
      page,
      createdAt,
      addedAt,
      deletedAt,
      color,
      lineWidth,
      paths,
      kind,
      rects = [],
      text,
      rect,
      sourcePoint,
      targetPoint,
      fontFamily,
      fontSize,
      fontWeight,
      textAlignment,
      anchorPoint,
      filename,
      content,
      commentId,
      newbie = true,
      _version = 1,
      _driver = "web",
      boundingData,
      collaboratorEmail,
      blobId,
      parentAnnotationId =  null
    } = objectData;

    const typeMapping = {
      ink: "ink",
      square: "shape",
      circle: "shape",
      arrow: "arrow",
      highlight: "markup",
      underline: "markup",
      strikeout: "markup",
      comment: "comment",
      reply: "comment",
      textbox: "textbox",
      image: "image",
    };
    const kindMapping = {
      ink: "ink",
      square: "box",
      circle: "oval",
      arrow: "arrow",
      highlight: "highlight",
      underline: "underline",
      strikeout: "strikeout",
      comment: "comment",
      CROSSLINE: "strikeout",
      MARKER: "highlight",
      UNDERLINE: "underline",
      reply: "reply",
      textbox: "textbox",
      image: "image",
    };
    const alignmentMapping = {
      START: "left",
      END: "right",
      CENTER: "center",
    };
    const typeName = __typename.replace(/Annotation$/, "").toLowerCase();
    // return {
    //   id: new A.RawString(id),
    //   type: new A.RawString(typeMapping[typeName] ?? "shape"),
    //   kind: new A.RawString(
    //     typeName === "highlight"
    //       ? kindMapping[kind]
    //       : kindMapping[typeName.toLowerCase()] ?? "box"
    //   ),
    //   created: new Date(createdAt).getTime(),
    //   modified: Date.now(), // No fields available atm.
    //   deleted: deletedAt ? new Date(deletedAt).getTime() : 0,
    //   userId: new A.RawString(collaboratorEmail), // not available atm
    //   frame: {
    //     x: boundingData?.left,
    //     y: boundingData?.top,
    //     w: boundingData?.right,
    //     h: boundingData?.bottom,
    //   },
    //   rect: rect
    //     ? {
    //         l: rect[0],
    //         r: rect[2],
    //         t: rect[3],
    //         b: rect[1],
    //       }
    //     : {},
    //   rects: rects.map((r) => {
    //     return {
    //       l: r[0],
    //       r: r[2],
    //       t: r[3],
    //       b: r[1],
    //     };
    //   }),
    //   paths,
    //   color: tinycolor(color).toRgb(),
    //   fontWeight: new A.RawString(
    //     fontWeight ? (fontWeight === "W400" ? "normal" : "bold") : undefined
    //   ),
    //   alignment: new A.RawString(
    //     textAlignment ? alignmentMapping[textAlignment] || "left" : undefined
    //   ),
    //   fontFamily: new A.RawString(fontFamily?.toLowerCase()),
    //   lineWidth,
    //   page,
    //   sourcePoint,
    //   targetPoint,
    //   text: new A.RawString(text),
    //   reference: new A.RawString(commentId),
    //   blobId: new A.RawString(blobId),
    //   filename: new A.RawString(filename),
    //   _version,
    //   _driver: new A.RawString(_driver),
    // };

    const annotationObject = {};

    if (id) annotationObject.id = new A.RawString(id);
    if (typeName) {
      annotationObject.type = new A.RawString(typeMapping[typeName] ?? "shape");
    }
    annotationObject.kind = new A.RawString(
      typeName === "highlight"
        ? kindMapping[kind]
        : kindMapping[typeName.toLowerCase()] ?? "box"
    );
    if (createdAt) annotationObject.created = new Date(createdAt).getTime();
    annotationObject.modified = Date.now(); // No fields available atm.
    annotationObject.deleted = deletedAt ? new Date(deletedAt).getTime() : 0;
    annotationObject.userId =
      collaboratorEmail?.val ?? new A.RawString(collaboratorEmail);

    if (boundingData) {
      annotationObject.frame = {
        l: boundingData.left ?? undefined,
        t: boundingData.top ?? undefined,
        r: boundingData.right ?? undefined,
        b: boundingData.bottom ?? undefined,
      };
    }

    if (rect) {
      annotationObject.rect = {
        l: rect[0],
        r: rect[2],
        t: rect[1],
        b: rect[3],
      };
    }

    if (rects && Array.isArray(rects)) {
      annotationObject.rects = rects.map((r) => ({
        l: r[0],
        r: r[2],
        t: r[1],
        b: r[3],
      }));
    }

    if (paths) {
      const pathsArray = [];
      paths.forEach((p) => {
        const pathPairs = [];
        for (let i = 0; i < p.length; i += 2) {
          pathPairs.push({ x: p[i], y: p[i + 1] });
        }
        pathsArray.push(pathPairs);
      });
      annotationObject.paths = pathsArray;
    }

    if (color) {
      const rgb = tinycolor(color).toRgb();
      annotationObject.color = {
        a: parseFloat(rgb.a), // alpha is already a float (range 0-1)
        r: parseFloat((rgb.r / 255).toFixed(5)), // converting to float between 0 and 1
        g: parseFloat((rgb.g / 255).toFixed(5)),
        b: parseFloat((rgb.b / 255).toFixed(5)),
      };
    }

    if (fontWeight !== undefined) {
      annotationObject.fontWeight = new A.RawString(
        fontWeight === "W400" ? "normal" : "bold"
      );
    }

    if (textAlignment) {
      annotationObject.alignment = new A.RawString(
        alignmentMapping[textAlignment] || "left"
      );
    }

    if (fontFamily) {
      annotationObject.fontFamily = new A.RawString(fontFamily.toLowerCase());
    }
    if(fontSize) annotationObject.fontSize = fontSize;
    if (lineWidth !== undefined) annotationObject.lineWidth = lineWidth;

    if (page !== undefined) annotationObject.page = page;

    if (sourcePoint !== undefined)
      annotationObject.sourcePoint = {
        x: sourcePoint[0],
        y: sourcePoint[1],
      };

    if (targetPoint !== undefined)
      annotationObject.targetPoint = {
        x: targetPoint[0],
        y: targetPoint[1],
      };

    if (text) annotationObject.text = new A.RawString(text);

    if (commentId) annotationObject.reference = new A.RawString(commentId);
    if (parentAnnotationId) annotationObject.reference = new A.RawString(parentAnnotationId);

    if (blobId) annotationObject.blobId = new A.RawString(blobId);

    if (filename) annotationObject.filename = new A.RawString(filename);

    if (_version !== undefined) annotationObject._version = _version;

    if (_driver) annotationObject._driver = new A.RawString(_driver);
    return annotationObject;
  }

  convertNewAnnotationObjectToOldVersion(newObject) {
    const {
      type,
      kind,
      created,
      modified,
      deleted,
      userId,
      frame,
      rect,
      paths,
      color,
      fontWeight,
      alignment,
      fontFamily,
      fontSize,
      lineWidth,
      page,
      id,
      sourcePoint,
      targetPoint,
      text,
      rects,
      reference,
      blobId,
      filename,
      content= null
    } = newObject;

    const reverseTypeMapping = {
      ink: "ink",
      shape: "shape",
      markup: "highlight",
      comment: "comment",
      textbox: "textbox",
      image: "image",
    };
    const reverseKindMapping = {
      ink: "ink",
      box: "square", // Default kind for 'box', can be 'arrow'
      oval: "circle",
      arrow: "arrow",
      highlight: "MARKER",
      underline: "UNDERLINE",
      strikeout: "CROSSLINE",
      comment: "comment",
      reply: "Reply",
      textbox: "textBox",
      image: "image",
    };
    const reverseAlignmentMapping = {
      left: "START",
      right: "END",
      center: "CENTER",
    };
    // console.log({ incomingObj: newObject });

    // Determine the original typename from the mappings
    const originalTypeName = reverseTypeMapping[type?.val ?? type] || "shape";
    const originalKind = reverseKindMapping[kind?.val ?? kind ?? type?.val] || "box";    
    // console.log({originalTypeName,
    //   originalKind});
    function revertPathPairs(pathsArray = []) {
      const revertedPaths = [];

      pathsArray.forEach((path) => {
        const flattenedPath = [];
        path.forEach((point) => {
          flattenedPath.push(point.x, point.y);
        });
        revertedPaths.push(flattenedPath);
      });
      return revertedPaths;
    }
    
    const finalObject = {
      __typename:
        originalTypeName === "highlight"
          ? "HighlightAnnotation"
          : `${
              originalKind.charAt(0).toUpperCase() + originalKind.slice(1)
            }Annotation`,
      id: id?.val ?? id, // Assuming id should be generated elsewhere, not available in newObject
      page,
      createdAt: created? new Date(created).toISOString():new Date().toISOString(),
      addedAt: modified?new Date(modified).toISOString():new Date().toISOString(), // Assuming addedAt is same as createdAt
      deletedAt: deleted ? new Date(deleted).toISOString() : null,
      color: tinycolor({
        a: color?.a,
        b: color?.b*255,
        r: color?.r*255,
        g: color?.g*255,
      }).toHex8String(),
      lineWidth,
      paths:revertPathPairs(paths),
      kind: originalKind,
      text: text?.val ?? text,
      rect: originalTypeName === 'shape' || originalTypeName === 'image'||originalTypeName === "textbox"?[frame?.l,frame?.t,frame?.r,frame?.b]:[rect?.l, rect?.t, rect?.r, rect?.b],
      rects: rects?.map((r) => {
        return [r.l, r.t, r.r, r.b];
      }),
      sourcePoint: [sourcePoint?.x, sourcePoint?.y],
      targetPoint: [targetPoint?.x, targetPoint?.y],
      fontFamily: fontFamily?.val?.toUpperCase() ?? fontFamily?.toUpperCase(),
      fontSize,
      fontWeight: fontWeight?.val
        ? fontWeight?.val === "bold"
          ? "W600"
          : "W400"
        : undefined,
      textAlignment: alignment?.val
        ? reverseAlignmentMapping[alignment?.val] || "START"
        : undefined,
      filename: filename?.val ?? filename,
      blobId: blobId?.val,
      commentId: kind?.val === "reply" ? reference.val : undefined,
      parentAnnotationId: kind !== "reply" && reference?.val,
      newbie: true, // Assuming newbie is true by default
      version: 1, // Assuming version 1 by default
      boundingData: {
        left: frame?.l,
        top: frame?.t,
        right: frame?.r,
        bottom: frame?.b,
      },
      collaboratorEmail: userId?.val ?? userId,
      anchorPoint: [frame?.l, frame?.t]
    };
    return finalObject;
    
  }

  convertStampLegacyAnnoTypeNames(type){
    return stampAnnotationName[type];
  }
}
