<script>
import { computed, reactive, inject, ref } from "vue";
import { BROADCAST_MESSAGE, PUBLIC_VIEWER, VIEWER, CONTRIBUTOR } from "../../core/constants.js";
import ProjectService from "../../service/projectService.js";
import Loader from '../common/loaders/defaultLoader.vue';

export default {
  name: "DownloadButton",
  props: {
    "userRole": String,
    "documentId": String,
    "fileName": String
  },
  components: { Loader },
  emits: ["downloadFlattenedPDF", "downloadEditedPDF"],
  setup(props, { emit }) {
    const $fetch = inject("$fetch");
    const projectService = new ProjectService();
    const state = reactive({
      optionsAreShown: false,
    });
    const isDownloading = ref(false);
    const userIsViewer = computed(() => {
      return !props.userRole || props.userRole === VIEWER || props.userRole === PUBLIC_VIEWER || props.userRole === CONTRIBUTOR;
    })

    function toggleOptionsList(flag) {
      console.log({ flag })
      if (props.userRole === VIEWER) return;
      state.optionsAreShown = flag === undefined ? !state.optionsAreShown : flag;
    }

    function downloadFlattenedPDF() {
      state.optionsAreShown = false;
      emit("downloadFlattenedPDF");
    }

    function downloadEditedPDF() {
      state.optionsAreShown = false;
      emit("downloadEditedPDF");
    }
    async function downloadAnnotatedPDF() {


      try {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          title: 'File download started',
          type: 'SUCCESS_TOAST',
          message: `file name: ${props?.fileName}`
        });
        const { content } = await projectService.downloadAnnotatedDocument({ documentId: props?.documentId });
        const binaryPDF = atob(content);
        const arrayBuffer = new Uint8Array(new ArrayBuffer(binaryPDF.length));
        for (let i = 0; i < binaryPDF.length; i++) {
          arrayBuffer[i] = binaryPDF.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const { fileName = 'downloaded.pdf' } = props;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          title: 'File download failed',
          type: 'ERROR_TOAST',
          message: `file name: ${props?.fileName}`
        });
      }

    }

    function onClickHandler(e) {
      const { role } = e.target.dataset;
      // temp download feature with annotated doc
      // downloadAnnotatedPDF();
      // switch (role) {
      //   case "SHOW-OPTIONS": {
      //     state.optionsAreShown = true;
      //     break;
      //   }
      //   case "DOWNLOAD-EDITED-PDF": {
      //     state.optionsAreShown = false;
      //     downloadEditedPDF();
      //     break;
      //   }
      //   case "DOWNLOAD-FLATTENED-PDF": {
      //     state.optionsAreShown = false;
      //     downloadFlattenedPDF();
      //     break;
      //   }
      //   case "OVERLAY": {
      //     state.optionsAreShown = false;
      //     break;
      //   }
      //   default: break;
      // }
      state.optionsAreShown = !state.optionsAreShown
    }
    async function downloadDocument(options) {
      const { flatten = true, original = false } = options;
      const documentId = props.documentId;
      const fileName = props?.fileName ?? 'file.pdf';
      isDownloading.value = true;
      const { content, originMimeType = 'application/pdf' } = await projectService.downloadDocument({ documentId, flatten, original, fileName });
      if (content === null) {
        isDownloading.value = false;
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          title: 'File download failed',
          type: 'ERROR_TOAST',
          message: `Please try agin later.`
        });
        return;
      }
      function downloadPDF() {
        const base64PDF = content.replace(/^data:application\/pdf;base64,/, '');
        const binaryPDF = atob(base64PDF);
        const arrayBuffer = new ArrayBuffer(binaryPDF.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryPDF.length; i++) {
          uint8Array[i] = binaryPDF.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: original ? originMimeType : 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = original ? `${fileName}` : `${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      downloadPDF();
      isDownloading.value = false;

    }
    return {
      state, userIsViewer,
      downloadFlattenedPDF, downloadEditedPDF, onClickHandler,
      downloadDocument,
      isDownloading
    }
  }
}
</script>

<template>
  <div v-if="state.optionsAreShown" class="download-button-options-overlay" data-role="OVERLAY" @click="onClickHandler">
  </div>
  <button v-if="!userIsViewer" class="download-button" data-role="DOWNLOAD" @click="onClickHandler">
    <svg class="ml-4" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9994 14.2038V2.75" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M20.4988 14.205V19.9319C20.4988 20.1488 20.4126 20.3569 20.2592 20.5104C20.1058 20.6638 19.8977 20.75 19.6807 20.75H4.31813C4.10115 20.75 3.89305 20.6638 3.73962 20.5104C3.5862 20.3569 3.5 20.1488 3.5 19.9319V14.205"
        stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.0924 10.1128L12.0018 14.2035L7.91113 10.1128" stroke="#334155" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <span class="ml-4">Download Document</span>
    <Loader v-if="isDownloading" class="max-w-6" />
    <button v-if="!userIsViewer && !isDownloading" class="inner-download-button"
      :class="{ 'upside-down': state.optionsAreShown === false }" data-role="SHOW-OPTIONS">
    </button>
    <div v-if="!isDownloading && state.optionsAreShown" class="download-button-options">
      <span @click="downloadDocument({ flatten: true })" class="button-option">Download printable PDF <br>
        <span class="text-gray-500 text-xs">Annotations flattened and not editable</span>
      </span>

      <!-- <span @click="downloadDocument({flatten:false})" class="button-option" >Download editable PDF</span> -->
      <span @click="downloadDocument({ original: true })" class="button-option">Download original File <br>
        <span class="text-gray-500 text-xs">Original file format with no annotations</span>
      </span>
    </div>
  </button>
</template>

<style scoped lang="scss">
.download-button-options-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: auto;
}

.download-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;

  width: 100%;
  height: 56px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #334155;
  user-select: none;

  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  border-radius: 8px;

  &:active {
    transform: translate(1px, 1px);
  }

  .inner-download-button {
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='5' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.16634 1.16699L2.99967 3.50033L0.833008 1.16699' stroke='%23334155' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 20px;

    &.upside-down {
      transform: rotate(180deg);
    }

    &:hover {
      background-color: rgba(219, 221, 222, 0.5);
    }
  }

  .download-button-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    width: 100%;
    bottom: 40px;
    padding: 15px 0px;

    background: #FFFFFF;
    border: 1px solid rgba(51, 65, 85, 0.15);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-left: -7px;
    margin-bottom: 20px;
    .button-option {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #334155;
      text-align: left;
      cursor: pointer;
      user-select: none;

      padding: 5px 15px;
      width: 100%;

      &:hover {
        background: rgba(219, 221, 222, 0.45);
      }
    }
  }
}
</style>